import React, { Component } from "react";
import Countdown from "./Countdown";
import { Navbar, Container, Col } from "react-bootstrap";

class App extends Component {
  render() {
    return (
      <Navbar variant="dark" expand="lg" id="site-navbar">
        <Container fluid>
          <Col lg={6} xs={12}>
            <div className="text-App">
              <p>Special Disc 25% Akan Berakhir Pada</p>
            </div>
          </Col>
          <Col lg={6} xs={12}>
            <div className="App">
              <Countdown date={`October 31, 2023 23:59:59`} />
            </div>
          </Col>
        </Container>
      </Navbar>
    );
  }
}
export default App;
